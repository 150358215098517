import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Input, DatePicker, Button, TimePicker, Typography } from 'antd';
import useForm from '@hooks/useForm';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { api } from '@helpers/api';
import { OnboardText } from '@pages/Onboard/style';
import { GenericSelect } from '@components/GenericSelect';
import FormField from '@components/common/FormField';

const { Title } = Typography;

interface FormOnboardProps {
  companyUuid: string;
  onClose: () => void;
  action: string;
}

interface TimeRangeInputProps {
  namePrefix: string;
  label: string;
  startPlaceholder: string;
  endPlaceholder: string;
  validateTimeDifference: (startTime: any, endTime: any) => boolean;
}

const TimeRangeInput = ({
  namePrefix,
  label,
  startPlaceholder,
  endPlaceholder,
  validateTimeDifference,
  ...props
}: TimeRangeInputProps) => {

  const { t } = useTranslation();

  return {
    label,
    component: (
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item 
            {...props}
            name={`${namePrefix}_start_at`} 
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || !getFieldValue(`${namePrefix}_end_at`)) {
                    return Promise.resolve();
                  }
                  if (validateTimeDifference(value, getFieldValue(`${namePrefix}_end_at`))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('FORMONBOARD.TIME_ERROR')));
                },
              }),
            ]}
          >
            <TimePicker format="HH:mm:ss" style={{ width: '100%' }} placeholder={startPlaceholder} showNow={false}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name={`${namePrefix}_end_at`} 
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || !getFieldValue(`${namePrefix}_start_at`)) {
                    return Promise.resolve();
                  }
                  if (validateTimeDifference(getFieldValue(`${namePrefix}_start_at`), value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('FORMONBOARD.TIME_ERROR')));
                },
              }),
            ]}
          >
            <TimePicker format="HH:mm:ss" style={{ width: '100%' }} placeholder={endPlaceholder} />
          </Form.Item>
        </Col>
      </Row>
    ),
    rules: [{ required: true }]
  };
};

const FormOnboard = ({ companyUuid, onClose, action }: FormOnboardProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { onFinish, disabled, contextHolder } = useForm(action, onClose, `/onboard/customer/${companyUuid}`);
  const [companyName, setCompanyName] = useState('');
  
  const segmentOptions = [
    { label: t('FORMONBOARD.TECHNOLOGY'), value: 'Tecnologia' },
    { label: t('FORMONBOARD.HEALTH'), value: 'Saúde' },
    { label: t('FORMONBOARD.EDUCATION'), value: 'Educação' },
    { label: t('FORMONBOARD.FINANCE'), value: 'Finanças' },
    { label: t('FORMONBOARD.RETAIL'), value: 'Varejo' },
    { label: t('FORMONBOARD.LOGISTICS'), value: 'Logística' },
    { label: t('FORMONBOARD.OTHER'), value: 'Outro' }
  ];

  const employeeSizeOptions = [
    { label: '1-10 ' + t('FORMONBOARD.EMPLOYEES'), value: '1-10' },
    { label: '11-50 ' + t('FORMONBOARD.EMPLOYEES'), value: '11-50' },
    { label: '51-200 ' + t('FORMONBOARD.EMPLOYEES'), value: '51-200' },
    { label: '201-500 ' + t('FORMONBOARD.EMPLOYEES'),value: '201-500' },
    { label: '501-1000 ' + t('FORMONBOARD.EMPLOYEES'), value: '501-1000' },
    { label: t('FORMONBOARD.MORETHAN1000'), value: '+1000' }
  ];

  const contactPreferences = [
    { value: 'E-mail', label: t('FORMONBOARD.EMAIL') },
    { value: 'WhatsApp privado', label: t('FORMONBOARD.PRIVATEWHATSAPP') },
    { value: 'Grupo de WhatsApp', label: t('FORMONBOARD.WHATSAPPGROUP') },
    { value: 'Ligação',  label: t('FORMONBOARD.PHONECALL')}
  ];

  useEffect(() => {
    const fetchCompanyName = async () => {
      try {
        const response = await api.get(`company/${companyUuid}`);
        setCompanyName(response.data.name);
      } catch (error) {
        setCompanyName('Company');
      }
    };
    fetchCompanyName();
  }, [companyUuid]);

  const formatTime = (time: dayjs.Dayjs | null) => {
    return time ? time.format('HH:mm:ss') : null;
  };

  const validateTimeDifference = (startTime: dayjs.Dayjs, endTime: dayjs.Dayjs) => {
    const diffInHours = endTime.diff(startTime, 'hour', true);
    return diffInHours >= 3 && endTime.isAfter(startTime);
  };

  const createSelect = (name: string, label: string, options: { label: string, value: string }[]) => ({
    name,
    label,
    component: (
      <GenericSelect
        name={name}
        options={options}
        noStyle
      />
    ),
    rules: [{ required: true }],
  });

  const createInput = (name: string, label: string, type?: string) => ({
    name,
    label,
    component: <Input type={type} />,
  });

  const createTextArea = (name: string, label: string, rows: number) => ({
    name,
    label,
    component: <Input.TextArea rows={rows} />,
    rules: [{ required: true }]
  });

  const formFields = [
    {
      name: 'birthday',
      label: t('FORMONBOARD.BIRTHDAY'),
      component: <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />,
      rules: [{ required: true }]
    },
    createSelect('cs_contact_preference', t('FORMONBOARD.CONTACT_PREFERENCE'), contactPreferences),
    createSelect('cs_segment', t('FORMONBOARD.SEGMENT'), segmentOptions),
    createSelect('cs_employees_size', t('FORMONBOARD.COMPANY_SIZE'), employeeSizeOptions), 
    TimeRangeInput({
      namePrefix: 'cs_working_time',
      label: t('FORMONBOARD.WORKING_TIME'),
      startPlaceholder: t('FORMONBOARD.START_TIME'),
      endPlaceholder: t('FORMONBOARD.END_TIME'),
      validateTimeDifference,
    }),    
    TimeRangeInput({
      namePrefix: 'cs_backup_time',
      label: t('FORMONBOARD.BACKUP_TIME'),
      startPlaceholder: t('FORMONBOARD.BACKUP_START_TIME'),
      endPlaceholder: t('FORMONBOARD.BACKUP_END_TIME'),
      validateTimeDifference,
    }),
    TimeRangeInput({
      namePrefix: 'cs_maintenance_time',
      label: t('FORMONBOARD.MAINTENANCE_TIME'),
      startPlaceholder: t('FORMONBOARD.MAINTENANCE_START_TIME'),
      endPlaceholder: t('FORMONBOARD.MAINTENANCE_END_TIME'),
      validateTimeDifference,
    }),
    { ...createInput('cs_users_active_usage', t('FORMONBOARD.SIMULTANEOUS_USERS'), 'number'), rules: [{ required: true }] },
    createTextArea('cs_partner_expectations', t('FORMONBOARD.PARTNER_EXPECTATIONS'), 4),
    createTextArea('cs_environment_obs', t('FORMONBOARD.ENVIRONMENT_OBS'), 4),
    createTextArea('cs_environment_usage', t('FORMONBOARD.ENVIRONMENT_USAGE'), 3),
    createTextArea('cs_first_questions', t('FORMONBOARD.FIRST_QUESTIONS'), 4),  
  ];

  const handleSubmit = async (values: any) => {
    values.cs_working_time_start_at = formatTime(values.cs_working_time_start_at);
    values.cs_working_time_end_at = formatTime(values.cs_working_time_end_at);
    values.cs_backup_time_start_at = formatTime(values.cs_backup_time_start_at);
    values.cs_backup_time_end_at = formatTime(values.cs_backup_time_end_at);
    values.cs_maintenance_time_start_at = formatTime(values.cs_maintenance_time_start_at);
    values.cs_maintenance_time_end_at = formatTime(values.cs_maintenance_time_end_at);

    onFinish(values);
  };

  const handleSubmitButton = () => {
    form.submit();
  }

  return (
    <>
      <OnboardText>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <p style={{textAlign: 'center'}}>{t('FORMONBOARD.JOURNEY')}</p>
            <p style={{textAlign: 'center'}}>{t('FORMONBOARD.QUESTIONNAIRE')}</p>
          </div>
      </OnboardText>
      <Title level={2} style={{ marginBottom: '1%', marginTop: '1%', display: 'flex', justifyContent: 'center' }}>{companyName}</Title>
      <Form form={form} layout="vertical" onFinish={handleSubmit} style={{ width:"95vw" }} data-cy="form-onboard">
        {contextHolder}
        <Row gutter={[16, 20]} style={{ width: "100%", padding: "3%", marginLeft: "2.5%", justifyContent: "end" }}>
          {formFields.map((field) => (
            <FormField
              key={field.name}
              name={field.name}
              label={field.label}
              rules={field.rules}
              xs={24}
              md={12}
            >
              {field.component}
            </FormField>          
          ))}
        </Row>
        <Row gutter={[16, 20]} style={{ width: "100%" }} justify="end">
          <Form.Item>
            <Button type="primary" onClick={handleSubmitButton} disabled={disabled}>
              {t('FORMONBOARD.SUBMIT')}
            </Button>
          </Form.Item>
        </Row> 
      </Form>
    </>
  );
};

export default FormOnboard;
