export const getAdditionalTabs = (dataType: string, items: any[]) => {
    let additionalKeys: string[] = []
  
    if (dataType === 'VM') {
      additionalKeys = ['6', '7']
    } else if (dataType === 'BAREMETAL') {
      additionalKeys = ['4', '5']
    }
  
    return additionalKeys
      .map(key => items.find(item => item.key === key))
      .filter((tab): tab is (typeof items)[0] => !!tab)
  }