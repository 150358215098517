import { useEffect, useState } from 'react';
import { Row, FloatButton, Col, Button, Badge } from 'antd';
import ModalReportTickets from '@components/Ticket/ModalReport';
import useSearchParams from '@hooks/useSearchParams';
import ClearFilterButton from '@components/common/ClearFilterButton';
import useModal from '@hooks/useModal';
import SubTitle from '@common/SubTitle';
import { useTranslation } from 'react-i18next';
import Icon from '@components/common/Icon';
import { CompanySelect } from '@components/common/CompanySelect';
import DeviceFilterSelect from '@components/common/DeviceFilterSelect';
import ModalFilter from '../ModalFilter';
import { useTheme } from 'styled-components';
import PriorityFilterSelect from '../PrioritySelect';
import RequestFilterSelect from '../RequestSelect';
import SearchByQueryInput from '@components/common/SearchByQueryInput';
import StatusFilterSelect from '../StatusSelect';
import TimeRangePicker from '../TimeRangePicker';
import SortSelect from '../SortSelect';
import NotAssignedSectorsSelect from '../NotAssignedSectorsSelect';
import AssignedSectorsSelect from '../AssignedSectorsSelect';
import ServiceSelect from '../ServiceSelect';
import AssignedUserSwitch from '../AssignedUserSwitch';
import SUsersSelect from '../SUsersTicketSelect';

export const ReportButton = () => {
  const { t } = useTranslation();
  const { open, onOpen, onCancel } = useModal();
  return (
    <>
      <ModalReportTickets
        title={t('EXPORTTICKETS')}
        open={open}
        onCancel={onCancel}
      />
      <FloatButton
        data-cy="open-report-ticket"
        style={{ right: 50, zIndex: 1 }}
        shape="square"
        onClick={onOpen}
        icon={<Icon name="fa-light fa-file-export" />}
      />
    </>
  );
};

const FilterSelect = ({ title, children }: { title: string, children: React.ReactNode }) => {
  return (
    <Col span={24} xl={{ span: 8 }}>
      <SubTitle level={5}>{title}</SubTitle>
      {children}
    </Col>
  );
};

const calculateFilterCount = (params: any) => {
  let count = 0;
  const filterKeys = [
    'assignedTo',
    'services',
    'assignedSectors',
    'notAssignedSectors',
    'created_at_start',
    'created_at_end',
    'updated_at_start',
    'updated_at_end',
    'sort',
    'device',
    'priority',
    'request',
    'company',
    'search',
    'requestBy',
    'status',
  ];

  filterKeys.forEach((key) => {
    if (params.getItem(key)) {
      count++;
    }
  });

  return count;
};

const FilterModalContent = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={[15, 24]} style={{ width: "100%" }} justify="center">
      <FilterSelect title={t('SEARCH')}><SearchByQueryInput span={24} /></FilterSelect>
      <FilterSelect title={t('STATUS')}><StatusFilterSelect /></FilterSelect>
      <FilterSelect title={t('ASSIGNEDTO')}><SUsersSelect /></FilterSelect>
      <FilterSelect title={t('SERVICE')}><ServiceSelect /></FilterSelect>
      <FilterSelect title={t('sector')}><AssignedSectorsSelect /></FilterSelect>
      <FilterSelect title={t('NOTASSIGNEDSECTOR')}><NotAssignedSectorsSelect /></FilterSelect>
      <FilterSelect title={t('DEVICE')}><DeviceFilterSelect /></FilterSelect>
      <FilterSelect title={t('PRIORITY')}><PriorityFilterSelect /></FilterSelect>
      <FilterSelect title={t('FILTERREQUESTBY')}><RequestFilterSelect /></FilterSelect>
      <FilterSelect title={t('COMPANY')}><CompanySelect span={24} /></FilterSelect>
      <FilterSelect title={t('TIME_RANGE')}><TimeRangePicker /></FilterSelect>
      <FilterSelect title={t('SORT_BY')}><SortSelect /></FilterSelect>
    </Row>
  );
};

export const FilterButton = () => {
  const { open, onOpen, onCancel } = useModal();
  const theme = useTheme();
  const params = useSearchParams(true);
  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    setFilterCount(calculateFilterCount(params));
  }, [params]);

  return (
    <Col>
      <Badge count={filterCount} style={{ backgroundColor: theme.blue, color: theme.white }}>
        <Button onClick={onOpen} style={{ backgroundColor: theme.blue, color: theme.white }}>
          <Icon name="fa-light fa-filter-list" color='white' />
        </Button>
      </Badge>

      <ModalFilter
        isOpen={open}
        setModalOpen={onOpen}
        onCancel={onCancel}
      >
        <FilterModalContent />
        <ClearFilterButton uri="/tickets" item="tickets" />
      </ModalFilter>    
    </Col>
  );
};

export const arrFilterTickets = [
  { span: 4, children: <AssignedUserSwitch /> },
  { span: 4, children: <SUsersSelect /> },
  { span: 5, children: <CompanySelect span={24} /> },
  { span: 4, children: <ServiceSelect /> },
  { span: 2, children: <ClearFilterButton uri="/tickets" item="tickets" /> }
];
