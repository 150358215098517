import { useEffect, useState } from 'react'
import Title from '@common/Title'
import Filter from '@components/Device/GroupFilter'
import SubTitle from '@common/SubTitle'
import { PlusOutlined } from '@ant-design/icons'
import ModalTicket from '@components/Ticket/Modal'
import { useUser } from '@contexts/UserContext'
import { columns } from '@components/Ticket/Table'
import { useTranslation } from 'react-i18next'
import { IDashboardTableCard } from '@/types/IDashboard'
import Alert from '@common/Alert'
import TicketList from '@components/Ticket/TicketList'
import useWindowResize from '@hooks/useWindowResize'
import useTable from '@hooks/useTable'
import Card from '@components/common/Card'
import { Col, Row, Space, Switch, Tag, Tooltip, Typography, Image } from 'antd'
import { useGenericContext } from '@hooks/useGenericContext'
import Table from '@components/common/Table'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import Icon from '@components/common/Icon'
import { DisplayContext } from '@contexts/DisplayContext'
import FeedbackContext from '@contexts/FeedbackContext'
import ClearFilterButton from '@components/common/ClearFilterButton'
import Summary from '@components/Dashboard/Summary'
import { Text } from '@components/common/Text'
import DeviceTableList from '@components/Device/DeviceTableList'
import { api } from '@helpers/api'

const FloatCreateTicket = () => {
  const { t } = useTranslation()
  return (
    <ModalTicket
      action="create"
      tooltip={t('CREATETICKET')}
      float={true}
      shape="square"
      type="primary"
      style={{ right: 50, zIndex: 1 }}
      icon={<PlusOutlined />}
    />
  )
}

const defineColor = (name: string) => {
  const keys = Object.keys(TICKETSTATUSESCOLORS)
  const definedKey =
    keys.find(key => key.includes(name.toUpperCase().split(' ')[0])) || 'NEW'
  return TICKETSTATUSESCOLORS[definedKey].color
}

const TableCard = ({ url, name }: IDashboardTableCard) => {
  const { list } = useGenericContext(DisplayContext)
  const props = useTable(url)
  const { width } = useWindowResize()
  if (width < 768) {
    return (
      <>
        <SubTitle>{name}</SubTitle>
        <Card {...props} columns={columns} />
      </>
    )
  }
  return (
    <>
      <SubTitle>
        {name + ' '}{' '}
        {props?.data?.meta?.total ? (
          <Tag
            color={defineColor(name) || 'blue-inverse'}
            style={{ borderRadius: '10px' }}
          >
            {props?.data?.meta?.total}
          </Tag>
        ) : null}
      </SubTitle>
      {list === 'list' ? (
        <TicketList {...props} />
      ) : (
        <Table
          {...props}
          columns={columns}
          handleTableChange={props.handleTableChange}
        />
      )}
    </>
  )
}

const ShowTables = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const uri = '/ticket?status='
  return (
    <>
      <TableCard url={`${uri}NEW`} name={t('NEWTICKETS')} />
      <TableCard url={`${uri}PENDING`} name={t('PENDINGTICKETS')} />
      {user.level !== 1 ? (
        <TableCard
          url={`${uri}PROCESSING_ATTRIBUTED&assignedTo=${user.uuid}`}
          name={t('ATRIBUTEDTICKETS')}
        />
      ) : null}
      <TableCard
        url={`${uri}PROCESSING_ATTRIBUTED,PROCESSING_PLANNED`}
        name={t('PROCESSINGTICKETS')}
      />
    </>
  )
}

const useSwicthDisplayButton = () => {
  const { setList, list } = useGenericContext(DisplayContext)
  const changeList = () => {
    setList(prevList => {
      const newDisplay = prevList === 'list' ? 'table' : 'list'
      localStorage.setItem('dashboardlist', newDisplay)
      return newDisplay
    })
  }
  return { list, changeList }
}

const SwitchDisplayButton = () => {
  const { list, changeList } = useSwicthDisplayButton()
  const { user } = useUser()
  const { t } = useTranslation()
  return (
    <Col xl={{ span: 24 }} xs={{ span: 24 }}>
      <Row justify="end" gutter={[16, 16]}>
        <Space>
          <Tooltip title={t('CLICKTOCHANGETHEVISUALIZATIONMODEL')}>
            <Text>{list === 'table' ? t('VIEWONTABLE') : t('VIEWONLIST')}</Text>
          </Tooltip>
          <Switch
            data-cy="switch-table-list"
            checkedChildren={<Icon name="fal fa-table-list" color="white" />}
            unCheckedChildren={<Icon name="fal fa-list" color="white" />}
            onClick={changeList}
          />
        </Space>
      </Row>
    </Col>
  )
}

const DashboardTitle = () => {
  const { user } = useUser()

  return (
    <Title name="Dashboard">
      <Row style={{ width: '100%' }} justify="space-between">
        <Typography.Title data-cy="title">Dashboard</Typography.Title>
        <Row align="middle" style={{ gap: '10px' }}>
          {user.level > 1 ? (
            <ClearFilterButton uri={'/'} item={'dashboard'} shape="round" />
          ) : null}
        </Row>
      </Row>
    </Title>
  )
}

const DeviceSection = () => {
  const { t } = useTranslation()
  return (
    <>
      <SubTitle style={{ marginTop: '8px' }}>{t('MYDEVICES')}</SubTitle>
      <DeviceTableList />
    </>
  )
}

const UserItems = () => (
  <Col span={24}>
    <Alert style={{ marginBottom: '2%', marginTop: '1%' }} />
    <Filter />
  </Col>
)

const OnboardingSection = () => {
  const { t } = useTranslation();
  return (
    <Row justify="center" style={{ padding: '20px', marginTop: '20px' }} data-cy="onboarding-section">
        <Image
          src="/assets/images/Email-CS_Fluxograma-5.png"
          alt="Imagem da jornada"
          preview={false}
          style={{ width: '100%', height: 'auto' }}
          data-cy="onboarding-image"
        />
      <Col xs={24} md={16} lg={12} style={{ textAlign: 'center', paddingTop: '25px' }}>
        <Space direction="vertical" size="large" data-cy="onboarding-success-message"> 
          <Typography.Text style={{ fontSize: '1.3em' }} >
            {t('FORMONBOARD.SUCCESS_MESSAGE')}
          </Typography.Text>
          <Typography.Text style={{ fontSize: '1.3em' }} >
            {t('FORMONBOARD.FINAL_MESSAGE')}
          </Typography.Text>
        </Space>
      </Col>
    </Row>
  );
};
const useOnboardData = () => {
  const [onboardStatus, setOnboardStatus] = useState('');

  useEffect(() => {
    const fetchOnboardData = async () => {
      try {
        const response = await api.get('/company');
        const company = response?.data?.data[0];

        company && setOnboardStatus(company.onboard_status);
      } catch (error) {
        setOnboardStatus('ERROR');
      }
    };
    fetchOnboardData();
  }, []);

  return { onboardStatus };
};

const Dashboard = () => {
  const [list, setList] = useState(() => {
    const current = localStorage.getItem('dashboardlist')
    return current ?? 'list'
  });
  const { user } = useUser();
  const { width } = useWindowResize();
  const { onboardStatus } = useOnboardData();

  return (
    <FeedbackContext>
      <DisplayContext.Provider value={{ list, setList }}>
        {onboardStatus === 'WAITING_FIRST_DEVICE_ACCESS_MAIL' && <OnboardingSection />}
        <FloatCreateTicket />
        <DashboardTitle />
        <Summary />
        <Row gutter={[8, 8]}>
          {user.level === 1 ? <UserItems /> : null}
          {width > 768 ? <SwitchDisplayButton /> : null}
        </Row>
        {user.level === 1 ? <DeviceSection /> : null}
        <ShowTables />
      </DisplayContext.Provider>
    </FeedbackContext>
  );
};

export default Dashboard;
