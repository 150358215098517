import { api } from '@helpers/api'
import { IOption } from '@/types/IOption'
import { Col, Form, message, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { handleError } from '@helpers/handleError'

interface Constants {
  span: number
  label: string
  constant: string
  required?: boolean
  inputName: string
}

export const SelectInput = ({
  span,
  label,
  constant,
  required,
  inputName
}: Constants) => {
  const [data, setData] = useState<IOption[]>()

  const { t } = useTranslation()
  const fetchConstants = async () => {
    try {
      const response = await api.get('/constants')
      const options: IOption[] = Object.entries(response.data[constant]).map(
        ([key, value]) => ({
          value: String(value),
          label: t(String(value))
        })
      )
      setData(options)
    } catch (error) {
      message.error(t('GETFORMDATAERROR'))
    }
  }
  useEffect(() => {
    fetchConstants()
  }, [])

  return (
    <Col xs={{ span: 6 }} xl={{ span: span }}>
      <Form.Item
        name={inputName}
        label={t(label)}
        rules={[{ required: required, message: t('requiredItem') }]}
      >
        <Select
          placeholder={t('CHOOSEANITEM')}
          options={data ? data.filter(option => option !== undefined) : []}
          data-cy="select-input"
        />
      </Form.Item>
    </Col>
  )
}
