import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '@helpers/api';

const FirstLogin = () => {
  const [isValidToken, setIsValidToken] = useState(false);
  const navigate = useNavigate();
  const { uuid: token } = useParams();

  useEffect(() => {
    console.log("Token from URL:", token);

    api.post('/session/fl', { token }).then(e => {
      if (e.data.token) {
        setIsValidToken(true);
        console.log("Valid token received:", e.data.token);
        navigate('/reset-password', { state: e.data.token});
        return
      }
      navigate('/')
    })
  }, [token, navigate]);
};

export default FirstLogin;
