import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { api } from '@helpers/api'
import { fetchWithFeedback } from '@helpers/fetchWithFeedback'

const useForm = (
  action: 'edit' | 'create',
  close: () => void,
  url: string,
  uuid?: string
) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [disabled, setDisabled] = useState(false)
  const [responseData, setResponseData] = useState<any>(null)
  const { t } = useTranslation()

  const handleRequest = async (request: Promise<any>) => {
    try {
      const response = await request
      setResponseData(response?.data)
    } finally {
      setDisabled(false)
    }
  }

  const onFinish = async (newObject: any) => {
    setDisabled(true)

    const requestUrl = action === 'edit' && uuid ? `${url}/${uuid}` : url
    const apiCall =
      action === 'edit'
        ? api.put(requestUrl, newObject)
        : api.post(requestUrl, newObject)

    await handleRequest(fetchWithFeedback(messageApi, close, apiCall, t))
  }

  return { onFinish, disabled, contextHolder, responseData }
}

export default useForm
